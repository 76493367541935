


















































































































































































































































import {Component, Vue} from "vue-property-decorator";
  import QuestDetailsResponsive from "@/views/QuestDetailsResponsive.vue";
  import CardDetails from "@/components/CardDetails.vue";
import {IndexDBClient} from "@/api/indexeddb/IndexDBClient";

  @Component({
    name: "ResponsiveLayout",
    components: {QuestDetailsResponsive, CardDetails}
  })
  export default class ResponsiveLayout extends Vue
  {
    sidebarOpen = false;
    notificationOpen = false;
    dropdownOpen = false;
    searching = false;

    search = "";

    constructor() {
      super();
    }

    get pageName()
    {
      return this.$route.name;
    }

    get showTimeline() {
      return this.$route.meta.timeline;
    }

    searchCards() {
        this.$store.state.searching = this.search;
    }

    closeSearching() {
      this.$store.state.searching = "";
      this.search = "";
      this.searching = false;
    }

    daysInMonth () {
      const date = new Date();
      return new Date(date.getFullYear(), date.getMonth(), 0).getDate();
    }

    dayOfWeek(day, firstLetter = true) {
      const year = new Date().getFullYear();
      const month = new Date().getMonth();
      const date = new Date(year, month, day-1);
      const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
      const abbr = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

      return firstLetter? daysOfWeek[date.getDay()] : abbr[date.getDay()];
    }

    goBack() {
     /* if(this.$route.query)
        this.$router.replace({'query': undefined});
      this.$router.back();*/
      this.$router.back();
    }


  }
