























import {Vue, Component, Prop} from "vue-property-decorator";
import QuestListItem from "@/components/QuestListItem.vue";

@Component({
  name: "QuestDetailsResponsive",
  components:{ QuestListItem}
})

export default class QuestDetailsResponsive extends Vue {
  @Prop({ default: false })
  protected isOpen!: boolean;

  @Prop({ default: "right" })
  protected position!: string;

  @Prop({ default: true })
  protected closable!: boolean;

  toggle() {
    this.$emit("asideMenuClose");
    this.$emit("save");
    if(Object.keys(this.$route.query).length !== 0)
      this.$router.push({path: this.$route.matched[0].path});
  }

  get getPosition(): string {
    return this.position + "-0";
  }

  get translateEnd(): string {
    return this.position === "left"
        ? "-translate-x-full ease-in"
        : "translate-x-full ease-in";
  }
}
