















































import {Component, Prop, Vue} from "vue-property-decorator";
@Component({
    name: "QuestListItem",
})
export default class QuestListItem extends Vue {

    @Prop({default: ""})
    protected questId!: string;

    @Prop({default: ""})
    protected questTitle!: string;

    @Prop({default: ""})
    protected questList!: string;

    @Prop({default: ""})
    protected questStatus!: string;

    @Prop({default: ""})
    protected questStartDate!: string;

  @Prop({default: ""})
  protected card!: string;

  @Prop({default: 1})
  protected gold!: number;

  @Prop({default: "code"})
  protected type!: string;

  get questType() {
    return ['fa', this.type];
  }

    constructor() {
        super();
    }

    get statusTextColour(): string {
        if(this.questStatus === "In Progress")
            return "text-green-400";
        if(this.questStatus === "On Hold")
            return "text-orange-400";
        if(this.questStatus === "Released")
            return "text-yellow-400";

        return "text-red-400";
    }

  showDetails() {
      this.$emit("showCardDetails");
  }

}
